import Button from "react-bootstrap/Button";
import Carousel from "react-bootstrap/Carousel";
import { slides } from "./slider-data";
import "./styles.css";

function renderItems({ image, header1, header2 }) {
  return (
    <Carousel.Item>
      <div className="overlay"></div>
      <div className="mobileView">
        <img
          className="d-block h-100 smallDevice"
          src={image}
          alt="First slide"
        />
      </div>
      <Carousel.Caption>
        <h1>{header1}</h1>
        <br />
        <h1>{header2}</h1>
        <br />
        <p></p>
        <br />
        <div>
          <a href="https://vendor.farmwallet.org/">
            <Button className="rounded-pill" variant="success">
              Vendor Registeration
            </Button>
            {"  "}
          </a>
        </div>
      </Carousel.Caption>
    </Carousel.Item>
  );
}

function HomeSlider() {
  return (
    <Carousel id="home">
      {slides.map((slide) => {
        return renderItems(slide);
      })}
    </Carousel>
  );
}

export default HomeSlider;
