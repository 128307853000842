// import Container from "react-bootstrap/Container";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
// import Button from "react-bootstrap/Button";
// import "./styles.css";
// import { logo } from "../../assets";

// function NavBarCollapsible() {
//   return (
//     <Navbar
//       collapseOnSelect
//       expand="lg"
//       bg="light"
//       sticky="top"
//       variant="light"
//     >
//       <Container>
//         <Navbar.Brand href="#home">
//           <img src={logo} alt="logo" style={{ width: 50, height: 50 }} />
//         </Navbar.Brand>
//         <Navbar.Toggle aria-controls="responsive-navbar-nav" />
//         <Navbar.Collapse id="responsive-navbar-nav">
//           <Nav className="me-auto"></Nav>
//           <Nav className="d-flex gap-5">
//             <Nav.Link href="#home">HOME</Nav.Link>
//             <Nav.Link href="#about">ABOUT</Nav.Link>
//             <Nav.Link href="#farmwallet">Join As</Nav.Link>
//             <Nav.Link href="#contact">CONTACT</Nav.Link>
//           </Nav>
//           {/* <Nav className='ms-5 d-flex gap-3'>
//             <Button className='rounded-pill sm-button' variant='success'>SIGN IN</Button>
//             <Button className='rounded-pill sm-button' variant='success'>SIGN UP</Button>
//           </Nav> */}
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
//   );
// }

// export default NavBarCollapsible;

import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import { logo } from "../../assets";

function NavBarCollapsible() {
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="light"
      sticky="top"
      variant="light"
    >
      <Container fluid>
        {" "}
        {/* Use fluid property to make the Container 100% responsive */}
        <Navbar.Brand href="#home">
          <img src={logo} alt="logo" style={{ width: 50, height: 50 }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {/* Add any additional links you want in the collapsed menu */}
          </Nav>
          <Nav className="d-flex gap-2 gap-lg-5">
            {/* Use the gap utility class with responsive variations */}
            <Nav.Link href="#home">HOME</Nav.Link>
            <Nav.Link href="#about">ABOUT</Nav.Link>
            <Nav.Link href="#farmwallet">DONATE</Nav.Link>
            <Nav.Link href="#contact">CONTACT</Nav.Link>
            {/* Add any additional links here */}
          </Nav>
          <Nav className="ms-2 ms-lg-5 d-flex gap-2 gap-lg-3">
            {/* Use the gap utility class with responsive variations */}
            {/* <Button className="rounded-pill sm-button" variant="success">
              SIGN IN
            </Button>
            <Button className="rounded-pill sm-button" variant="success">
              SIGN UP
            </Button> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBarCollapsible;
