import React from "react";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Stack from 'react-bootstrap/Stack'
import {green} from '../../utils/constants'
import { Container } from "react-bootstrap";
import { Facebook, Instagram, Linkedin, Twitter } from "react-bootstrap-icons";
import Logo from '../../assets/sectigo.jpg'
import MyWidgetComponent from "./whasapp";

function Footer(){
   
    return(
      
    <div  className="pt-5" id="contact" style={{backgroundColor:green, color:'#fff'}}>
    <Container>
      <Row>
        <Col md={3}>
            <div>
                <h5>Contact Us</h5>
                <p>SF 32 Mallet Street, Tamale Norther Region</p>
                <p>support@farmwallet.org</p>
                <p>+233555227753</p>
            </div>
        </Col>
        <Col md={3}>
            <div>
                <h5>Contact Us</h5>
                <p>41 Quartey Papafio Ave, Accra</p>
                <p>support@farmwallet.org</p>
                <p>+233266800577</p>
            </div>
        </Col>
        <Col md={3}>
            <div>
            <MyWidgetComponent/>
            </div>
        </Col>
        <Col md={{ span: 2, offset: 1 }}>
            <Stack gap={2} direction="horizontal">
                <img src={Logo} alt="SECTIGO Trust Seal"  />
                <img src="https://www.positivessl.com/images/seals/positivessl_trust_seal_lg_222x54.png"alt="PositiveSSL Trust Seal"/>
                
              
            </Stack>
        </Col>
        <Col md={{ span: 3, offset: 1 }}>
            <Stack gap={3} direction="horizontal">
               <a href="https://www.linkedin.com/company/goonacredit/posts/?feedView=all&viewAsMember=true/" target="__blank"><Linkedin size={25} color="#fff"/></a> 
                <a href="https://twitter.com/farmwallet/" target="__blank"><Twitter size={25} color="#fff"/></a>
                <a href="https://www.facebook.com/farmwallet/" target="__blank"><Facebook size={25} color="#fff"/></a> 
              </Stack>
        </Col>
       
      </Row>
      </Container>
    </div>
    )
}

export default Footer;