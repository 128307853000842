import React from "react";
import { design } from "../../assets";
import "./styles.css";
import * as sdgs from "../../assets/sdgs";
import { Button, Row, Col } from "react-bootstrap";

function HowSection() {
  return (
    <div
      id="farmwallet"
      className="how-section"
      style={{ backgroundImage: `url(${design})`, color: "white" }}
    >
      <div className="how-inn ">
        <h4 id="join">Empower Smallholder Farmers: Donate Inputs, Cultivate Change</h4>
        <div
          className="h-1 mx-auto mt-3 underline"
          style={{ backgroundColor: "#ffffff" }}
        ></div>
        <p className="mt-5">
        Empowerment: Your contribution directly empowers smallholder farmers by providing them with essential inputs like seeds, fertilizers, and tools.
Sustainable Agriculture: Donated inputs contribute to the adoption of sustainable farming practices, enhancing crop yields and promoting environmental stewardship.
Community Development: By supporting farmers, you contribute to the economic development of entire communities, fostering resilience and prosperity.
        </p>
        <div className="mt-4">
          <Row gap={5}>
            {Object.values(sdgs).map((sdgs) => (
              <Col id="how-img">
                <img src={sdgs} style={{ width: 100, height: 100 }} alt="sdg" />
              </Col>
            ))}
          </Row>
          <p>
            <a href="https://paystack.com/pay/b0z09gvkof">
              <Button
                className="rounded-pill"
                variant="light"
                style={{ marginTop: "10px" }}
              >
                Donate 
              </Button>
              {"  "}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default HowSection;
