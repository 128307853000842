import {
    Routes,
    Route
  } from "react-router-dom";

import HomePage from "../pages/Home/home";
import Error404 from "../pages/404/error404";
  


function Router(){
    return(
        <Routes>
            <Route path="/" element={<HomePage/>} />
            <Route path="/home" element={<HomePage/>} />
            <Route path="*" element={<Error404 />} />
      </Routes>
    )
}

export default Router;