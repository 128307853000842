import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as partners from "../../assets/partners";
import "./styles.css";

function Partners() {
  return (
    <Container className="p-5 pb-5 vw-100">
      <div className="mb-5">
        <Row>
          <div className="vh-10">
            <h2>Partners</h2>
          </div>
        </Row>
        <div className="h-1 mx-auto mt-3 underline"></div>
      </div>
      <div className="d-flex flex-wrap">
        {Object.values(partners).map((partner, index) => (
          <Col key={index} id="partners-img" className="mb-3">
            <img
              src={partner}
              className="img-fluid rounded"
              style={{ maxWidth: "100px", maxHeight: "100px" }}
              alt={`Partner ${index + 1}`}
            />
          </Col>
        ))}
      </div>
    </Container>
  );
}

export default Partners;
