import React from 'react';
import Stack from 'react-bootstrap/Stack';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {CardList, CreditCard, Cash, Person, PersonDash, PersonBadge, PersonHeart, PersonWorkspace, PersonBoundingBox, Bank, CashCoin} from 'react-bootstrap-icons'
import './styles.css'
import { green } from '../../utils/constants';


function walletSection(){
    return(
        <Container className='pt-5 pb-5 section-height' id="howitworks">
             <Row>
                <div className='vh-10 mt-5'>
                    <h2>Our Services</h2>
                </div>
            </Row>
            <div className='h-1 mx-auto mt-3 underline'></div>
            <div className='spacer'></div>
            <Row>
                <Col xs={12} md={4} className='m-0 p-0'>
                    <Stack>
                        <div className='d-flex justify-content-center mb-5'>
                            <div className='outline-circle'>
                                <Bank size={100} color={green} />
                            </div>
                        </div>
                        <div className='w-100 bottom'>
                            <div className='h-1 mx-auto mt-3 underline negative-bottom'></div>
                        </div>
                        <div className='m-4'>
                            <h5 className='title'>Digital Identity and Financial Inclusion</h5>
                            <p>Farmers would receive a smart card with a wallet that they could use to pay for input, Mechanization service , and extension services.</p>
                        </div>
                    </Stack>
                </Col>
                <Col xs={12} md={4} className='m-0 p-0'>
                    <Stack>
                        <div className='d-flex justify-content-center mb-5'>
                            <div className='outline-circle'>
                                <CashCoin size={100} color={green} />
                            </div>
                        </div>
                        <div className='w-100 bottom'>
                            <div className='h-1 mx-auto mt-3 underline negative-bottom'></div>
                        </div>
                        <div className='m-4'>
                            <h5 className='title'>Seamless Transactions</h5>
                            <p>Our platform enables secure and transparent transactions, eliminating the need for cash-based exchanges and reducing risks for both farmers and stakeholders in the agricultural value chain.</p>
                        </div>
                    </Stack>
                </Col>
                <Col xs={12} md={4} className='m-0 p-0'>
                    <Stack>
                        <div className='d-flex justify-content-center mb-5'>
                            <div className='outline-circle'>
                                <CreditCard size={100} color={green} />
                            </div>
                        </div>
                        <div className='w-100 bottom'>
                            <div className='h-1 mx-auto mt-3 underline negative-bottom'></div>
                        </div>
                        <div className='m-4'>
                            <h5 className='title'>Credit Assessment and Lending</h5>
                            <p>Powered by AI algorithms, our proprietary credit rating system assesses farmers' creditworthiness, enabling financial institutions to offer tailored lending solutions that meet their unique needs.</p>
                        </div>
                    </Stack>
                </Col>
            </Row>
        </Container>
    )
}

export default walletSection