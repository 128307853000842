import React from "react";
import AboutSection from "../../components/aboutSection";
import HomeSlider from "../../components/carousel";
import Footer from "../../components/footer";
import HowSection from "../../components/howSection";
import Partners from "../../components/partners";
import WalletSection from "../../components/walletSection";
import "./home.css";
function HomePage() {
  return (
    <div id="home">
      <HomeSlider />
      <AboutSection />
      <WalletSection />
      <HowSection />
      <Partners />
      <Footer />
    </div>
  );
}

export default HomePage;
