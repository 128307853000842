import logo from './logo.svg';
import './App.css';
import NavBarCollapsible from './components/header/navbar';
import HomePage from './pages/Home/home';
import { BrowserRouter} from "react-router-dom";
import Router from './routes/router';


function App() {
  return (
    <div className="App">
     <BrowserRouter>
        <NavBarCollapsible />
        <Router />
     </BrowserRouter>
    </div>
  );
}

export default App;
