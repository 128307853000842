import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles.css";
import {
  HandThumbsUp,
  Speedometer2,
  Wallet2,
  PersonBadge,
} from "react-bootstrap-icons";
import { green } from "../../utils/constants";

function About() {
  return (
    <Container className="pt-5 pb-5 vw-100" id="about">
      <Row>
        <div className="vh-10 mt-5">
          <h2>About Us</h2>
        </div>
      </Row>
      <div className="h-1 mx-auto mt-3 underline"></div>

      <Row className="mt-5" style={{ height: "50%", alignItems: "center" }}>
        <Col xs={12} md={6}>
          <Row>
            <Col xs={12} md={2} className="center-small">
              <div className="iconbg">
                <PersonBadge color={green} size={50} />
              </div>
            </Col>
            <Col xs={12} md={10}>
              <div className="text-md-start ms-4">
                <h2>Who We Are</h2>
                <p>
                  FarmWallet is a team of dedicated and passionate individuals,
                  driven by a shared vision of a world where farmers have equal
                  access to financial services and technology, transforming
                  their livelihoods and fostering economic growth.
                </p>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6}>
          <Row>
            <Col xs={12} md={2} className="center-small">
              <div className="iconbg">
                <HandThumbsUp color={green} size={50} />
              </div>
            </Col>
            <Col xs={12} md={10}>
              <div className="text-md-start ms-4">
                <h2>Our Vision</h2>
                <p>
                  To build a global agricultural ecosystem that empowers
                  smallholder farmers with financial inclusion, cutting-edge
                  technology, and market access, leading to thriving communities
                  and a sustainable future for all.
                </p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ height: "50%", alignItems: "center" }}>
        <Col xs={12} md={6}>
          <Row>
            <Col xs={12} md={2} className="center-small">
              <div className="iconbg">
                <Speedometer2 color={green} size={50} />
              </div>
            </Col>
            <Col xs={12} md={10}>
              <div className="text-md-start ms-4">
                <h2>Our Mission</h2>
                <p>
                  To leverage blockchain and artificial intelligence to create a
                  seamless, secure, and efficient platform for farmers to access
                  credit, input suppliers, and financial services, ultimately
                  elevating their productivity and income levels.
                </p>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6}>
          <Row>
            <Col xs={12} md={2} className="center-small">
              <div className="iconbg">
                <Wallet2 color={green} size={50} />
              </div>
            </Col>
            <Col xs={12} md={10}>
              <div className="text-md-start ms-4">
                <h2>Social Impact</h2>
                <p>
                  Join us in our pursuit of financial inclusion, poverty
                  reduction, and sustainable agriculture, contributing to
                  positive change in the lives of smallholder farmers and their
                  communities.
                </p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default About;
