import React, { useEffect } from 'react';

const MyWidgetComponent = () => {
  useEffect(() => {
    const config = {
      phone: "233266800577",
      call: "Message Us",
      position: "ww-right",
      size: "ww-medium",
      text: "",
      type: "ww-standard",
      brand: "FarmWallet",
      subtitle: "",
      welcome: ""
    };

    const proto = document.location.protocol;
    const host = "cloudfront.net";
    const url = proto + "//d3kzab8jj16n2f." + host;
    
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = url + "/v2/main.js";
    
    script.onload = () => {
      window.tmWidgetInit(config);
    };
    
    document.getElementsByTagName("script")[0].parentNode.insertBefore(script, document.getElementsByTagName("script")[0]);

    // Clean up function to remove the script when the component unmounts
    return () => {
      document.getElementsByTagName("script")[0].parentNode.removeChild(script);
    };
  }, []);

  return (
    <div>
      {/* Add any other elements or components you want to render */}
    </div>
  );
};

export default MyWidgetComponent;