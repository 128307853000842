import { slide1, slide2, slide3, slide5, slide6 } from "../../assets";

const slides = [
  {
    image: slide1,
    header1: "Welcome to FarmWallet Empowering Farmers Transforming Lives, ",
    header2: "Dial *920*72#	",
  },
  {
    image: slide2,
    header1: "Welcome to FarmWallet Empowering Farmers Transforming Lives, ",
    header2: "Dial *920*72#	",
  },
  {
    image: slide3,
    header1: "Welcome to FarmWallet Empowering Farmers Transforming Lives, ",
    header2: "Dial *920*72#	",
  },
  {
    image: slide5,
    header1: "Welcome to FarmWallet Empowering Farmers Transforming Lives, ",
    header2: "Dial *920*72#	",
  },
  {
    image: slide6,
    header1: "Welcome to FarmWallet Empowering Farmers Transforming Lives, ",
    header2: "Dial *920*72#	",
  },
];

export { slides };
